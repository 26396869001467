import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-alert"
export default class extends Controller {

  connect() {
    console.log('custom alert loaded')
  }

  openModal(event) {
    event.preventDefault()
    const modalId = event.currentTarget.dataset.modalId
    console.log(modalId)
    const modal = document.getElementById(modalId)
    if (modal) {
      modal.classList.add("show")
      modal.style.display = "block"
      modal.setAttribute("aria-hidden", "false")
      modal.setAttribute("aria-modal", "true")
    }
  }

  closeModal(event) {
    event.preventDefault()
    const modalId = event.currentTarget.dataset.modalId
    console.log(modalId)
    const modal = document.getElementById(modalId)
    if (modal) {
      modal.classList.remove("show");
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
      modal.removeAttribute("aria-modal");
    }
  }
}
