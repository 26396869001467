import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

// Connects to data-controller="forms"
export default class extends Controller {
  static targets = ["varietiesSelect", "input", "checkbox", "form", "chained", "table"]
  static values = {
    model: String,
    url: String,
    method: String,
    object: Object,
    disabledButtonText: String,
    dependents: Array,
    dependentsOf: Array
  }

  initialize() {
    this.element.addEventListener("turbo:submit-end", (e) => {
      this.next(e)
    })
  }

  next(event) {
    if (event.detail.success) {
      let {url} = event.detail.fetchResponse.response
      history.pushState({data_turbo_history: "replace"}, "", url)
      Turbo.navigator.history.push(url)
    }
  }

  showSectionBySelectedValue(event) {
    this.toggle(event)
  }

  /*
  * Esto funciona aunque es un poco fumada :D a parte que no consigo que el this.values pille el valor
  * Al input checkbox que tenga dependencias de si mismo las cuales no pueden estar checked si el no está checked se le asigna en el data { dependents_value: []}
  * dentro del array se ponen los nombres de los checkbox que dependen de el
  *
  * Por el contrario al checkbox que depende de otro para poder pasarse se le asigna en el data { dependents_of_value: [] } en el array irian los nombres de los checkbox
  * que tienen que estar activos para el poder estar activo
  * */
  toggleDependents(event) {
    try {
      let dependents = event.target.dataset.dependentsValue
      let dependentsOf = event.target.dataset.dependentsOfValue

      if (dependents) {
        dependents = JSON.parse(dependents)
        for (const name of dependents) {
          const elements = document.querySelectorAll(`input[name='${name}'][type=checkbox]`)
          for (const element of elements) {
            element.checked = false
          }
        }
      }

      if (dependentsOf) {
        dependentsOf = JSON.parse(dependentsOf)
        event.target.checked = !event.target.checked ? event.target.checked : dependentsOf.every(name =>
          Array.from(document.querySelectorAll(`input[name='${name}'][type=checkbox]`))?.every(element => element.checked)
        )
      }

    } catch (e) {
      console.error(e)
    }
  }

  toggle(event) {
    const id = Object.keys(event.params).length ? event.params.targetId : `${event.target.value.toLowerCase()}`
    const element = document.getElementById(id)
    const checkboxes = element.getElementsByClassName('form-check-input')
    // if (element.classList.contains('d-none')) element.classList.remove("d-none")
    // else element.classList.add("d-none");

    if (element && element.classList.contains('d-none')) {
      element.classList.remove("d-none")
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].removeAttribute('disabled')
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].setAttribute('disabled', true)
      }
      event.target.name = event.target.name.replace(event.params.modelName, '')
      element && element.classList.add("d-none");
    }
  }

  levelsToggle(event) {
    const id = Object.keys(event.params).length ? event.params.targetId : `${event.target.value.toLowerCase()}`
    const element = document.getElementById(id)

    if ((event.params && event.params.level && event.params.level === 3) || event.target.value === "3") {
      if (element.classList.contains('d-none')) element.classList.remove('d-none')
      else element.classList.add('d-none')
    }
  }

  closeToggle(event) {
    event.preventDefault()

    const {open, close} = event.params
    const {checked} = event.target
    const label = this.element.getElementsByTagName('label')[0]

    label.innerHTML = checked ? open : close
  }

  entitySuspendedUntilToggle(event) {
    const {target, params} = event
    const {value} = target
    const {targetId} = params

    const element = document.getElementById(targetId)
    let selects = document.getElementsByTagName('select')
    selects = selects && Array.from(selects).filter((s) => s.id !== "standalone_proposal_entity_status_cd")

    if (element) {
      if (Number(value) === 2) {
        element.classList.remove('hidden')
        selects && selects.length && selects.forEach((s) => s.disabled = true)
      } else {
        element.classList.add('hidden')
        selects && selects.length && selects.forEach((s) => s.removeAttribute('disabled'))
        const input = element.getElementsByTagName('input')[0]
        if (input) input.value = ''
      }
    } else return
  }

  showDateSelectors(event) {
    event.preventDefault()

    const {target, params} = event
    const {value} = target
    const {targetId} = params

    const element = document.getElementById(targetId)

    if (element) {
      if (Number(value) === 2) element.classList.remove('d-none')
      else {
        element.classList.add('d-none')
        const inputs = element.getElementsByTagName('input')
        if (inputs.lentgh) inputs.forEach((input) => input.value = '')
      }
    } else return
  }

  disableOthers(event) {
    const elements = Array.from(document.querySelectorAll(`.${event.params.targetClass}`))
    const ids = elements.map((e) => e.id)
    const aux = ids.filter((id, index) => ids.indexOf(id) !== index)
    const set = [...new Set(aux)]
    const repeated = document.querySelectorAll(`#${set[0]}`)

    if (repeated.length > 1) {
      const last = repeated[repeated.length - 1]
      const newId = `${event.params.targetClass}-${Date.now()}`
      last.id = newId
      last.nextElementSibling.children[0].setAttribute("for", newId)
    }

    for (const element of elements) {
      if (element.id !== event.target.id) {
        if (event.type === "change") {
          if (event.target.checked) {
            element.checked = false
            element.setAttribute("disabled", "disabled")
          } else element.removeAttribute("disabled")

          if (element.id === event.target.id && element.hasAttribute("disabled")) element.removeAttribute("disabled")
        }

        if (event.type === "click" || event.type === "update") {
          if (elements.some((e) => e.checked)) {
            const checkedElement = elements.filter((e) => e.checked)[0]

            if (checkedElement !== element && elements.some((e) => e.checked)) {
              element.checked = false
              element.setAttribute("disabled", "disabled")
            }
          } else element.removeAttribute("disabled")
        }
      }
    }
  }

  async change(event) {
    try {
      let query = {entity_id: event.target.value}
      let params = ''

      if (!event.params.object) {
        const form = document.forms.new_inspection
        const data = new FormData(form)

        for (const [name, value] of data.entries()) {
          if (value !== '' && name !== 'authenticity_token') {
            params += `&${name}=${value}`
          }
        }
      }

      const {ok} = await get(
        `${this.urlValue}`,
        {
          responseKind: "turbo-stream",
          query: query
        }
      )
    } catch (e) {
      console.error({e})
    }
  }

  async refresh(event) {
    const {url, productiveSpaceId, option, targetClass} = await event.params
    let elements = document.querySelectorAll(`.${targetClass}`)
    let ids = Array.from(elements).map((e) => e.id)
    let id = ids[ids.length - 1]
    let last = document.getElementById(`${id}`)

    let destinationUrl = url

    if (url) {
      if (last) {
        const select = document.getElementById("productive_spaces_item_itemable_id")

        if (select.selectedOptions[0].value) {
          destinationUrl += `/${select.selectedOptions[0].value}/${option}?target=${last.id}`
          if (productiveSpaceId) destinationUrl += `&productive_space_id=${productiveSpaceId}`

          const {ok} = await get(`${destinationUrl}`, {responseKind: "turbo-stream"})

          if (ok) {
            // Elements to remove in nested child attributes
            // this.refreshVarietiesList(event)
          }
        }

        return
      }

      if (this.varietiesSelectTarget) {
        let target = this.varietiesSelectTarget.id
        destinationUrl += `/${event.target.selectedOptions[0].value}/${option}?target=${target}`
        if (productiveSpaceId) destinationUrl += `&productive_space_id=${productiveSpaceId}`
        await get(`${destinationUrl}`, {responseKind: "turbo-stream"})
      }
    }
  }

  async refreshVarietiesList(event) {
    // const { origin } = location
    // const select = document.getElementById("productive_spaces_item_itemable_id")
    // const { url, productiveSpaceId, option, targetClass } = await event.params
    // const elements = document.querySelectorAll(`.${targetClass}`)
    // const elementIds = Array.from(elements).map((e) => e.id)
    // //const id = ids[ids.length - 1]
    //
    // let lastElementId, selectedOptions, selectedValues = []
    // let destinationUrl = `${origin}/${url}/${select.selectedOptions[0].value}/${option}?target=`
    //
    // if (event.type !== "click") lastElementId = elementIds.filter((id) => id !== event.target.id)
    // else lastElementId = elementIds[elementIds.length - 1]
    //
    // if (Array.isArray(lastElementId)) {
    //   lastElementId.forEach(async (id) => {
    //     let thatUrl = `${destinationUrl}${id}`
    //     if (productiveSpaceId) thatUrl = `${thatUrl}&productive_space_id=${productiveSpaceId}`
    //
    //     const sleep = new Promise((resolve) => setTimeout(resolve, 50))
    //     const awake = await sleep
    //
    //     selectedOptions = Array.from(elements).map(
    //       (element) => Array.from(element.options).filter(
    //         (option) => !!option.selected
    //       )
    //     ).flat()
    //
    //     if (selectedOptions.length) {
    //       selectedValues = selectedOptions.map((option) => Number(option.value))
    //     }
    //
    //     if (selectedValues.length && selectedValues.length > 1) {
    //       thatUrl = `${thatUrl}&to_exclude=${[...new Set(selectedValues.slice(0, selectedValues.length - 1))]}`
    //       await get(thatUrl, { responseKind: "turbo-stream" })
    //     }
    //   })
    // } else {
    //   const last = document.getElementById(`${lastElementId}`)
    //   let thatUrl = `${destinationUrl}${last ? last.id : option}`
    //
    //   if (productiveSpaceId) thatUrl = `${thatUrl}&productive_space_id=${productiveSpaceId}`
    //
    //   const sleep = new Promise((resolve) => setTimeout(resolve, 50))
    //   const awake = await sleep
    //
    //   selectedOptions = Array.from(elements).map(
    //     (element) => Array.from(element.options).filter(
    //       (option) => !!option.selected
    //     )
    //   ).flat()
    //
    //   if (selectedOptions.length) {
    //     selectedValues = selectedOptions.map((option) => Number(option.value))
    //   }
    //
    //   if (selectedValues.length && selectedValues.length > 1) {
    //     thatUrl = `${thatUrl}&to_exclude=${[...new Set(selectedValues.slice(0, selectedValues.length - 1))]}`
    //     await get(thatUrl, { responseKind: "turbo-stream" })
    //   }
    // }
    return
  }

  async getStandaloneProposals(event) {
    event.preventDefault()

    const { target, params } = event
    const { form, value } = target
    const { url } = params

    if (url) {
      console.log(url)
      const { ok } = await get(`${url}?q[companies_norm_id]=${value}`, { responseKind: "turbo-stream" })
      if (ok && form.method === "post") document.getElementById("save-button").classList.remove("disabled")
    }
  }

  async verify(event) {
    const checkboxes = document.querySelectorAll('.checkbox')
    if (event.target.value.length >= 3) {
      const {json} = await get(`/users/verify?id_card_number=${event.target.value}`, { responseKind: "json" })
      const {users} = await json

      this.inputTarget.removeAttribute('disabled')
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].removeAttribute('disabled')
      }
    } else {
      this.inputTarget.setAttribute('disabled', true)

      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].setAttribute('disabled', true)
      }
    }
  }

  async search(event) {
    const {action} = this.formTarget
    const data = new FormData(this.formTarget)
    for (const [key, value] of data.entries()){
      console.log(key, value)
    }
    const params = new URLSearchParams(Object.fromEntries(data.entries()))

    clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      // const { ok } = await get(`${action}&${params}`, { responseKind: "turbo-stream", data })
      const {ok} = await get(`${action}`, {responseKind: "turbo-stream", data, query: params})
      // history.pushState({ data_turbo_history: "replace" }, {}, `${action}&${params}`)
      // this.formTarget.requestSubmit()
    }, 200)
  }

  submit(event) {
    // const { action } = event.target.form
    // if (action) {
    //
    // }
    event.target.form.requestSubmit()
  }

  // No me convence ponerlo aqui pero mientras
  goBack() {
    history.back()
  }
}
